<template>
  <div>
    <div class="vb__utils__content">
      <a-affix :offset-top="0">
        <a-page-header class="header-affix">
          <a-form>
            <div class="row">
              <div class="col-12 mb-2">
                <a-radio-group @change="changeSelection" :disabled="loading" v-model:value="selection">
                  <a-radio-button class="mb-2 mb-md-0" value="all">Все время</a-radio-button>
                  <a-radio-button class="mb-2 mb-md-0" value="year">Год</a-radio-button>
                  <a-radio-button class="mb-2 mb-md-0" value="month">Месяц</a-radio-button>
                  <a-radio-button class="mb-2 mb-md-0" value="week">Неделя</a-radio-button>
                  <a-radio-button class="mb-2 mb-md-0" value="today">Сегодня</a-radio-button>
                </a-radio-group>
                <a-range-picker
                  class="ml-md-4"
                  :disabled-date="disabledDate"
                  @change="updateDate"
                  v-model:value="dateRange"
                  v-if="selection !== 'all'"
                  :placeholder="['Выберите дату', 'Выберите дату']"
                />
              </div>
            </div>
          </a-form>
        </a-page-header>
      </a-affix>
      <div class="row mt-4 mb-4">
        <div class="col-lg-12">
          <div class="row" style="padding: 0 11px">
            <div class="col-xl-2 px-1 col-md-3 mb-2 col-12 w20percent">
              <div class="card border-0 rounded-lg text-black mb-0 h-100" v-if="loading">
                <div class="card-body">
                  <a-skeleton avatar active :paragraph="{ rows: 0 }" />
                </div>
              </div>
              <div class="card border-0 rounded-lg text-black mb-0 h-100 position-relative" v-else>
                <div class="card-body">
                  <div class="d-flex flex-wrap align-items-center">
                    <i class="fa fa-custom font-size-10"><img height="30" src="/resources/images/location.svg" alt=""></i>
                    <div class="card-content">
                      <div class="font-size-14">Кол-во локаций</div>
                      <div class="font-size-18 font-weight-bold">{{ storesCount }}</div>
                    </div>
                    <div class="card-chart position-absolute">
<!--                      <ApexChart type="area" height="100" :options="chartOptions3" :series="series3"></ApexChart>-->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-2 px-1 col-md-3 mb-2 col-12 w20percent">
              <div class="card border-0 rounded-lg text-black mb-0 h-100" v-if="loading">
                <div class="card-body">
                  <a-skeleton avatar active :paragraph="{ rows: 0 }" />
                </div>
              </div>
              <div class="card border-0 rounded-lg text-black mb-0 h-100" v-else>
                <div class="card-body">
                  <div class="d-flex flex-wrap align-items-center">
                    <i class="fa fa-custom font-size-10"><img height="30" src="/resources/images/group.svg" alt=""></i>
                    <div class="card-content">
                      <div class="font-size-14">Кол-во сотрудников</div>
                      <div class="font-size-18 font-weight-bold">{{ employeesCount }}</div>
                    </div>
                    <div class="card-chart position-absolute">
<!--                      <ApexChart type="area" height="100" :options="chartOptions3" :series="series3"></ApexChart>-->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-2 px-1 col-md-3 mb-2 col-12 w20percent">
              <div class="card border-0 rounded-lg mb-0 h-100" v-if="loading">
                <div class="card-body">
                  <a-skeleton avatar active :paragraph="{ rows: 0 }" />
                </div>
              </div>
              <div class="card border-0 rounded-lg text-black mb-0 h-100" v-else>
                <div class="card-body">
                  <div class="d-flex flex-wrap align-items-center">
                    <i class="fa fa-custom font-size-10"><img height="30" src="/resources/images/payment.svg" alt=""></i>
                    <div class="card-content">
                      <div class="font-size-14">Оплачено счетов (new)</div>
                      <div class="font-size-21 font-weight-bold">{{ "80%" }}</div>
                    </div>
                    <div class="card-chart position-absolute">
<!--                      <ApexChart type="area" height="100" :options="chartOptions3" :series="series3"></ApexChart>-->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-2 px-1 col-md-3 mb-2 col-12 w20percent">
              <div class="card border-0 rounded-lg mb-0 h-100" v-if="loading">
                <div class="card-body">
                  <a-skeleton avatar active :paragraph="{ rows: 0 }" />
                </div>
              </div>
              <div class="card border-0 rounded-lg text-black mb-0 h-100" v-else>
                <div class="card-body">
                  <div class="d-flex flex-wrap align-items-center">
                    <i class="fa fa-custom font-size-10"><img height="30" src="/resources/images/data_check.svg" alt=""></i>
                    <div class="card-content">
                      <div class="font-size-14">Кол-во чеков (new)</div>
                      <div class="font-size-21 font-weight-bold">{{ "78%" }}</div>
                    </div>
                    <div class="card-chart position-absolute">
<!--                      <ApexChart type="area" height="100" :options="chartOptions3" :series="series3"></ApexChart>-->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-2 px-1 col-md-3 mb-2 col-12 w20percent">
              <div class="card border-0 rounded-lg mb-0 h-100" v-if="loading">
                <div class="card-body">
                  <a-skeleton avatar active :paragraph="{ rows: 0 }" />
                </div>
              </div>
              <div class="card border-0 rounded-lg text-black mb-0 h-100" v-else>
                <div class="card-body">
                  <div class="d-flex flex-wrap align-items-center">
                    <i class="fa fa-custom font-size-10"><img height="30" src="/resources/images/checks.svg" alt=""></i>
                    <div class="card-content">
                      <div class="font-size-14">Средний счёт</div>
                      <div class="font-size-21 font-weight-bold">{{ formattedBalance(avgAmount) }}</div>
                    </div>
                    <div class="card-chart position-absolute">
<!--                      <ApexChart type="area" height="100" :options="chartOptions3" :series="series3"></ApexChart>-->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-2 px-1 col-md-3 mb-2 col-12 w20percent">
              <div class="card border-0 rounded-lg mb-0 h-100" v-if="loading">
                <div class="card-body">
                  <a-skeleton avatar active :paragraph="{ rows: 0 }" />
                </div>
              </div>
              <div class="card border-0 rounded-lg mb-0 h-100" v-else>
                <div class="card-body">
                  <div class="d-flex flex-wrap align-items-center">
                    <i class="fa fa-custom font-size-10"><img height="30" src="/resources/images/paid.svg" alt=""></i>
                    <div class="card-content">
                      <div class="font-size-12">Собрано чаевых</div>
                      <div class="font-size-10">За {{ dateUpdated ? "выбранное время" : "30 дней" }}</div>
                      <div class="font-size-20 font-weight-bold">{{ formattedBalance(tipsAmount) }}</div>
                    </div>
                    <div class="card-chart position-absolute">
<!--                      <ApexChart type="area" height="100" :options="chartOptions3" :series="series3"></ApexChart>-->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-2 px-1 col-md-3 mb-2 col-12 w20percent">
              <div class="card border-0 rounded-lg mb-0 h-100" v-if="loading">
                <div class="card-body">
                  <a-skeleton avatar active :paragraph="{ rows: 0 }" />
                </div>
              </div>
              <div class="card border-0 rounded-lg text-black mb-0 h-100" v-else>
                <div class="card-body">
                  <div class="d-flex flex-wrap align-items-center">
                    <i class="fa fa-custom font-size-10"><img height="30" src="/resources/images/shopping_bag.svg" alt=""></i>
                    <div class="card-content">
                      <div class="font-size-14">Кол-во чаевых (new)</div>
                      <div class="font-size-21 font-weight-bold">{{ "89%" }}</div>
                    </div>
                    <div class="card-chart position-absolute">
<!--                      <ApexChart type="area" height="100" :options="chartOptions3" :series="series3"></ApexChart>-->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-2 px-1 col-md-3 mb-2 col-12 w20percent">
              <div class="card border-0 rounded-lg mb-0 h-100" v-if="loading">
                <div class="card-body">
                  <a-skeleton avatar active :paragraph="{ rows: 0 }" />
                </div>
              </div>
              <div class="card border-0 rounded-lg text-black mb-0 h-100" v-else>
                <div class="card-body">
                  <div class="d-flex flex-wrap align-items-center">
                    <i class="fa fa-custom font-size-10"><img height="30" src="/resources/images/request_money.svg" alt=""></i>
                    <div class="card-content">
                      <div class="font-size-14">Средний чек чаевых (new)</div>
                      <div class="font-size-21 font-weight-bold">{{ 0 }}</div>
                    </div>
                    <div class="card-chart position-absolute">
<!--                      <ApexChart type="area" height="100" :options="chartOptions3" :series="series3"></ApexChart>-->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-4" v-if="user.role === 'manager'">
        <div class="col-lg-12">
          <a-tabs v-model:activeKey="tab">
            <a-tab-pane key="store">
              <template #tab>
                <span>
                  Рейтинг заведений (за {{ dateUpdated ? "выбранное время" : "30 дней" }})
                </span>
              </template>
              <div class="table-responsive">
                <a-table @change="storeTableChange" :loading="loading" :pagination="false" :columns="storeColumn"
                         :data-source="storeRating">
                  <template #key="{ index }">{{ index + 1 }}</template>
                  <template #name="{ record }"><span class="ellipsis">{{ record.name }}</span></template>
                  <template #logo="{ record }">
                    <a-avatar v-if="record.logo_url !== ''" shape="square" :size="50" :src="record.logo_url" />
                  </template>
                  <template #rating="{ record }">
                    <StarTwoTone :two-tone-color="ratingColor(record.rating)" />&nbsp;{{ record.rating }}
                    ({{ record.success_payments }})
                  </template>
                  <template #tips_amount="{ record }">{{ formattedBalance(record.tips_amount) }}</template>
                </a-table>
              </div>
            </a-tab-pane>
            <a-tab-pane key="employee" force-render>
              <template #tab>
                <span>
                  Рейтинг сотрудников (за {{ dateUpdated ? "выбранное время" : "30 дней" }})
                </span>
              </template>
              <div class="table-responsive">
                <a-table @change="employeeTableChange" :loading="loading" :pagination="false" :columns="employeeColumn"
                         :data-source="employeeRating">
                  <template #key="{ index }">{{ index + 1 }}</template>
                  <template #name="{ record }"><span class="ellipsis">{{ record.name }}</span></template>
                  <template #photo="{ record }">
                    <a-avatar v-if="record.photo_url !== ''" shape="square" :size="50" :src="record.photo_url" />
                  </template>
                  <template #rating="{ record }">
                    <StarTwoTone :two-tone-color="ratingColor(record.rating)" />&nbsp;{{ record.rating }}
                  </template>
                  <template #tips_amount="{ record }">{{ formattedBalance(record.tips_amount) }}</template>
                </a-table>
              </div>
            </a-tab-pane>
            <template #renderTabBar="{ DefaultTabBar, ...props }">
              <component
                :is="DefaultTabBar"
                v-bind="props"
                :style="{ zIndex: 1, background: '#fff' }"
              />
            </template>
          </a-tabs>
        </div>
      </div>
      <div class="row" v-else>
        <div class="col-lg-6">
<!--          <ApexChart v-if="series.length" type="line" height="350" :options="chartOptions" :series="series"></ApexChart>-->
        </div>
        <div class="col-lg-6">
<!--          <ApexChart type="radar" height="350" :options="chartOptionsRadar" :series="series2Radar"></ApexChart>-->
        </div>
        <div class="col-lg-6">
          <div class="card">
            <div class="card-header">
              <div class="vb__utils__heading mb-0">
                <strong>Рейтинг заведений</strong>
              </div>
              <div class="text-muted">
                (за {{ dateUpdated ? "выбранное время" : "30 дней" }})
              </div>
            </div>
            <div class="card-body">
              <a-table @change="storeTableChange" :loading="loading" :pagination="false" :columns="storeColumn"
                       :data-source="storeRating">
                <template #key="{ index }">{{ index + 1 }}</template>
                <template #name="{ record }"><span class="ellipsis">{{ record.name }}</span></template>
                <template #logo="{ record }">
                  <a-avatar v-if="record.logo_url !== ''" shape="square" :size="50" :src="record.logo_url" />
                </template>
                <template #rating="{ record }">
                  <StarTwoTone :two-tone-color="ratingColor(record.rating)" />&nbsp;{{ record.rating_text }}
                  ({{ record.success_payments }})
                </template>
                <template #tips_amount="{ record }">{{ formattedBalance(record.tips_amount) }}</template>
              </a-table>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="card">
            <div class="card-header">
              <div class="vb__utils__heading mb-0">
                <strong>Рейтинг сотрудников</strong>
              </div>
              <div class="text-muted">
                (за {{ dateUpdated ? "выбранное время" : "30 дней" }})
              </div>
            </div>
            <div class="card-body">
              <a-table @change="employeeTableChange" :loading="loading" :pagination="false" :columns="employeeColumn"
                       :data-source="employeeRating">
                <template #key="{ index }">{{ index + 1 }}</template>
                <template #name="{ record }"><span class="ellipsis">{{ record.name }}</span></template>
                <template #photo="{ record }">
                  <div v-if="record.photo_url === '' || record.photo_url == null" class="employee-photo">
                    <img width="47" src="https://cdn.multicard.uz/storage/29e/e8576059ab6c74182ab7797992857.png" alt="">
                  </div>
                  <a-avatar v-else shape="square" :size="50" :src="record.photo_url" />
                </template>
                <template #rating="{ record }">
                  <StarTwoTone :two-tone-color="ratingColor(record.rating)" />&nbsp;{{ record.rating_text }}
                </template>
                <template #tips_amount="{ record }">{{ formattedBalance(record.tips_amount) }}</template>
              </a-table>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4">
          <div class="vb__utils__heading mb-3">
            <strong>Последние отзывы</strong>
          </div>
          <div v-if="loading">
            <a-skeleton active :paragraph="{ rows: 4 }" />
          </div>
          <div v-else>
            <div class="card">
              <div class="card-body">
                <div class="d-flex flex-nowrap align-items-start pt-4" v-for="(review, key) in latestReviews"
                     :key="key">
                  <div class="vb__utils__avatar vb__utils__avatar--size64 mr-4 flex-shrink-0 align-self-start"
                       v-if="review.store.logo_url !== ''">
                    <img :src="review.store.logo_url" :alt="review.store.name">
                  </div>
                  <div class="flex-grow-1">
                    <div class="vb__g15__contentContainer">
                      <div class="d-flex flex-wrap mb-2">
                        <div class="mr-auto">
                          <div class="text-gray-6">
                            <span class="text-dark font-weight-bold">{{ formattedUsername(review.user?.name) }}</span>
                            <a class="ml-2" :href="'tel:' + review.user?.phone">
                              <a-tooltip>
                                <template #title>
                                  {{ formattedPhone(review.user?.phone) }}
                                </template>
                                <a-tag color="processing">
                                  <template #icon>
                                    <phone-outlined />
                                  </template>
                                  Позвонить
                                </a-tag>
                              </a-tooltip>
                            </a>
                          </div>
                          <div>{{ review.store.name }}</div>
                        </div>
                      </div>
                      <div class="mb-3">{{ review.comment }}</div>
                      <div class="d-flex flex-wrap justify-content-start align-items-start mb-3">
                        <span v-for="i in review.rating" :key="i">
                          <StarTwoTone :two-tone-color="ratingColor(review.rating)" />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="vb__utils__heading mb-3">
            <strong>Последние чаевые</strong>
          </div>
          <div class="row">
            <div class="col-lg-12" v-if="loading">
              <a-skeleton active :paragraph="{ rows: 4 }" />
            </div>
            <div class="col-lg-12" v-else>
              <div class="card" v-for="(tip, key) in latestTips" :key="key">
                <div class="card-body">
                  <div class="d-flex flex-wrap align-items-center">
                    <div class="mr-auto">
                      <p class="text-uppercase text-dark font-weight-bold mb-1">
                        {{ tip.card }}
                      </p>
                      <p class="text-gray-5 mb-0">
                        {{ formattedDate(tip.added_on) }}
                      </p>
                    </div>
                    <p class="text-success font-weight-bold font-size-24 mb-0">
                      {{ formattedBalance(tip.amount) }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="vb__utils__heading mb-3">
            <strong>Последние счета</strong>
          </div>
          <div class="row">
            <div class="col-lg-12" v-if="loading">
              <a-skeleton active :paragraph="{ rows: 4 }" />
            </div>
            <div class="col-lg-12" v-else>
              <div class="card" v-for="(order, key) in latestOrders" :key="key">
                <div class="card-body">
                  <div class="d-flex flex-wrap align-items-center">
                    <div class="mr-auto">
                      <p class="text-uppercase text-dark font-weight-bold mb-1">
                        {{ order.card }}
                      </p>
                      <p class="text-gray-5 mb-0">
                        {{ formattedDate(order.added_on) }}
                      </p>
                    </div>
                    <p class="text-success font-weight-bold font-size-24 mb-0">
                      {{ formattedBalance(order.payment.bill_amount, true) }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <vb-tips-chart :date="dateRange" :key="chartKey" />
        <vb-orders-chart :date="dateRange" :key="chartKey" />
        <vb-tips-store-chart :date="dateRange" :key="chartKey" />
        <vb-orders-store-chart :date="dateRange" :key="chartKey" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { StarTwoTone, PhoneOutlined } from "@ant-design/icons-vue";
import VbOrdersStoreChart from "@/views/dashboard/orders-store-chart";
import VbTipsStoreChart from "@/views/dashboard/tips-store-chart";
import VbOrdersChart from "@/views/dashboard/orders-chart";
import VbTipsChart from "@/views/dashboard/tips-chart";
import apiClient from "@/services/axios";
import { computed, reactive, ref } from "vue";
import { useStore } from "vuex";
import { mask } from "maska";
import moment from "moment";
// import ApexChart from "vue3-apexcharts";

const user = computed(() => useStore().getters["user/user"]);
// eslint-disable-next-line
const
  chartOptions = reactive({
    chart: {
      height: 350,
      type: "line",
      stacked: false
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      width: [1, 1, 4]
    },
    title: {
      text: "XYZ - Stock Analysis (2009 - 2016)",
      align: "left",
      offsetX: 110
    },
    xaxis: {
      categories: [2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016]
    },
    yaxis: [
      {
        axisTicks: {
          show: true
        },
        axisBorder: {
          show: true,
          color: "#4b7cf3"
        },
        labels: {
          style: {
            colors: "#4b7cf3"
          }
        },
        title: {
          text: "Income (thousand crores)",
          style: {
            color: "#4b7cf3"
          }
        },
        tooltip: {
          enabled: true
        }
      },
      {
        seriesName: "Income",
        opposite: true,
        axisTicks: {
          show: true
        },
        axisBorder: {
          show: true,
          color: "#000"
        },
        labels: {
          style: {
            colors: "#000"
          }
        },
        title: {
          text: "Operating tips",
          style: {
            color: "#000"
          }
        }
      },
      {
        seriesName: "Revenue",
        opposite: true,
        axisTicks: {
          show: true
        },
        axisBorder: {
          show: true,
          color: "#F9222E"
        },
        labels: {
          style: {
            colors: "#F9222E"
          }
        },
        title: {
          text: "Revenue (thousand crores)",
          style: {
            color: "#F9222E"
          }
        }
      }
    ],
    tooltip: {
      fixed: {
        enabled: true,
        position: "topLeft", // topRight, topLeft, bottomRight, bottomLeft
        offsetY: 30,
        offsetX: 60
      }
    },
    legend: {
      horizontalAlign: "left",
      offsetX: 40
    }
  }),
  chartOptionsRadar = reactive({
    chart: {
      height: 450,
      type: "radar",
      dropShadow: {
        enabled: true,
        blur: 1,
        left: 1,
        top: 1
      }
    },
    title: {
      text: "Стикеры"
    },
    stroke: {
      width: 2
    },
    fill: {
      opacity: 0.1
    },
    markers: {
      size: 0
    },
    xaxis: {
      categories: ["Кухня", "Атмосфера", "Офитсянты", "Службы", "Локатсии", "Другие"]
    }
  }),
  chartOptions3 = reactive({
    chart: {
      height: 150,
      type: "area",
      zoom: {
        enabled: false
      },
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
      width: 2,
      lineCap: 'butt',
      colors: undefined,
      dashArray: 0,
    },
    title: {
      text: undefined,
      align: "left"
    },
    annotations: {
      show: false,
    },
    grid: {
      show: false
    },
    tooltip: {
      show: false,
      enabled: false,
    },
    xaxis: {
      labels: {
        show: false
      },
      axisTicks: {
        show: false
      },
      axisBorder: {
        show: false
      },
    },
    yaxis: {
      labels: {
        show: false
      },
    },
  });

// eslint-disable-next-line
const dataSticker = ref([
  {
    id: 1,
    name: 'Кухня',
    rates: [
      {
        rate: 1,
        count: 10,
      },
      {
        rate: 2,
        count: 14,
      },
      {
        rate: 3,
        count: 5,
      },
      {
        rate: 4,
        count: 20,
      },
      {
        rate: 5,
        count: 28,
      },
    ]
  },
  {
    id: 2,
    name: 'Атмосфера',
    rates: [
      {
        rate: 1,
        count: 10,
      },
      {
        rate: 2,
        count: 20,
      },
      {
        rate: 3,
        count: 30,
      },
      {
        rate: 4,
        count: 40,
      },
      {
        rate: 5,
        count: 50,
      },
    ]
  },
]);
let plus = 0;
let sum = 0;
dataSticker.value.forEach(s => {
  sum = s.rates.map(m => m.rate * m.count).reduce((a, b) => a + b, 0);
  plus = s.rates.reduce((a, b) => a + b.count, 0);
});

let result = (sum / plus).toFixed(2);
// console.log(result);
const series = ref([
    {
      name: "Income",
      type: "column",
      data: [1.4, 2, 2.5, 1.5, 2.5, 2.8, 3.8, 4.6]
    }, {
      name: "Cashflow",
      type: "column",
      data: [1.1, 3, 3.1, 4, 4.1, 4.9, 6.5, 8.5]
    }, {
      name: "Revenue",
      type: "line",
      data: [20, 29, 37, 36, 44, 45, 50, 58]
    }
  ]);
  // 1*10 + 2*20 + 3*30 + 4*40 + 5*50) / (10 + 20 + 30 + 40 + 50) = (10 + 40 + 90 + 160 + 250) / 150 = 550 / 150 = 3.67

  // "Кухня", "Атмосфера", "Официанты", "Службы", "Локации", "Другие"
  const series2Radar = ref([{
    name: "Oqtepa 1",
    data: [result, 4, 4.5, 2, 5, 3.8]
  }, {
    name: "Oqtepa 2",
    data: [3, 3, 5, 5, 4, 3]
  }, {
    name: "Oqtepa 3",
    data: [4.5, 4.5, 2, 5, 3, 1]
  }]);
  const series3 = ref([{
    name: undefined,
    data: [10, 41, 35, 51, 49, 62, 69, 91, 108]
  }]);

const storeColumn = computed(() => {
    const sorted = storeSortInfo.value || {};
    return [
      {
        title: "№",
        dataIndex: "key",
        key: "key",
        slots: {
          customRender: "key"
        }
      },
      {
        title: "Лого",
        dataIndex: "logo_url",
        key: "logo_url",
        slots: {
          customRender: "logo"
        }
      },
      {
        title: "Название",
        dataIndex: "name",
        key: "name",
        sorter: (a, b) => a.name.length - b.name.length,
        sortOrder: sorted.columnKey === "name" && sorted.order,
        slots: {
          customRender: "name"
        }
      },
      {
        title: "Ср. рейтинг",
        dataIndex: "rating",
        key: "rating",
        sorter: (a, b) => a.success_payments - b.success_payments,
        sortOrder: sorted.columnKey === "rating" && sorted.order,
        slots: {
          customRender: "rating"
        }
      },
      {
        title: "Кол.во чаевых",
        dataIndex: "paid_orders",
        key: "paid_orders",
        sorter: (a, b) => a.paid_orders - b.paid_orders,
        sortOrder: sorted.columnKey === "paid_orders" && sorted.order,
        slots: {
          customRender: "paid_orders"
        }
      },
      {
        title: "Чаевые",
        dataIndex: "tips_amount",
        key: "tips_amount",
        sorter: (a, b) => a.tips_amount - b.tips_amount,
        sortOrder: sorted.columnKey === "tips_amount" && sorted.order,
        slots: {
          customRender: "tips_amount"
        }
      }
    ];
  }),
  employeeColumn = computed(() => {
    const sorted = employeeSortInfo.value || {};
    return [
      {
        title: "№",
        dataIndex: "key",
        key: "key",
        slots: {
          customRender: "key"
        }
      },
      {
        title: "Фото",
        dataIndex: "photo_url",
        key: "photo_url",
        slots: {
          customRender: "photo"
        }
      },
      {
        title: "Название",
        dataIndex: "name",
        key: "name",
        sorter: (a, b) => a.name.length - b.name.length,
        sortOrder: sorted.columnKey === "name" && sorted.order,
        slots: {
          customRender: "name"
        }
      },
      {
        title: "Рейтинг",
        dataIndex: "rating",
        key: "rating",
        sorter: (a, b) => a.rating - b.rating,
        sortOrder: sorted.columnKey === "rating" && sorted.order,
        slots: {
          customRender: "rating"
        }
      },
      {
        title: "Кол.во чаевых",
        dataIndex: "paid_orders",
        key: "paid_orders",
        sorter: (a, b) => a.paid_orders - b.paid_orders,
        sortOrder: sorted.columnKey === "paid_orders" && sorted.order,
        slots: {
          customRender: "paid_orders"
        }
      },
      {
        title: "Чаевые",
        dataIndex: "tips_amount",
        key: "tips_amount",
        sorter: (a, b) => a.tips_amount - b.tips_amount,
        sortOrder: sorted.columnKey === "tips_amount" && sorted.order,
        slots: {
          customRender: "tips_amount"
        }
      }
    ];
  });

let
  storeSortInfo = ref(),
  employeeSortInfo = ref(),
  chartKey = ref(1),
  selection = ref("month"),
  dateUpdated = ref(false),
  dateRange = ref([moment().subtract(1, "months").add(1, "day"), moment()]),
  tab = ref("store"),
  loading = ref(true),
  storesCount = ref(0),
  employeesCount = ref(0),
  paymentsCount = ref(0),
  tipsAmount = ref(""),
  avgAmount = ref(""),
  storeRating = ref([]),
  employeeRating = ref([]),
  latestReviews = ref([]),
  latestTips = ref([]),
  latestOrders = ref([]);


const disabledDate = current => {
  return current && current > moment().endOf("day");
};

const formattedBalance = (balance, penny = true) => {
  let formatter = new Intl.NumberFormat("RU", {
    style: "currency",
    currency: "UZS"
  });
  let actualBalance = penny ? balance / 100 : balance;
  let formattedBalance = formatter.format(actualBalance);
  return formattedBalance.substring(0, formattedBalance.length - 7);
};

function formattedUsername(s) {
  return s ? s.toLowerCase().replace(/\b./g, function(a) {
    return a.toUpperCase();
  }) : "";
}

const ratingColor = rating => {
  if (rating === 1)
    return "#ff3459";
  if (rating === 2)
    return "#ff8fa3";
  if (rating === 3)
    return "#e1c12c";
  if (rating === 4)
    return "#86e686";
  if (rating === 5)
    return "#00b200";
};
const formattedPhone = (phone) => {
  return (phone) ? mask(phone, "+998(##) ###-##-##") : "";
};
const formattedDate = (value) => {
  let diff = moment(value).diff(moment(), "days");
  let uint = number => Math.sqrt(Math.pow(number, 2));
  let date;
  if (uint(diff) > 1) {
    date = moment(value).locale("ru").format("YYYY.MM.DD в HH:mm");
  } else {
    date = moment(value).locale("ru").calendar();
  }
  return date;
};

const changeSelection = (event) => {
  dateUpdated.value = true;
  let option = event.target.value;

  let from,
    to = moment();

  if (option === "month") {
    from = moment().subtract(1, "months").add(1, "day");
    // from = moment().startOf('month')
  }
  if (option === "week") {
    from = moment().subtract(1, "weeks").add(1, "day");
    // from = moment().weekday(1)
  }
  if (option === "year") {
    from = moment().subtract(1, "years").add(1, "day");
    // from = moment().startOf('year')
  }
  if (option === "today") {
    from = moment();
  }
  if (option === "all") {
    dateRange.value = [];
    getData();
    chartKey.value++;
    return;
  }

  dateRange.value = [from, to];
  getData([
    from.toISOString().substring(0, from.toISOString().indexOf("T")),
    to.toISOString().substring(0, to.toISOString().indexOf("T"))
  ]);
  chartKey.value++;
};

const getData = (date = false) => {
  loading.value = true;
  let url = date ? `dashboard?from=${date[0]}&to=${date[1]}` : "dashboard";
  apiClient.get(url).then(({ data }) => {
    loading.value = false;
    storesCount.value = data.data["counts"].store;
    employeesCount.value = data.data["counts"].employee;
    paymentsCount.value = data.data["counts"]["bill_count"];
    tipsAmount.value = data.data["counts"].tips;
    avgAmount.value = data.data["counts"]["tips_avg"];
    storeRating.value = data.data.stores;
    employeeRating.value = data.data["employees"];
    latestReviews.value = data.data.reviews;
    latestTips.value = data.data.tips;
    latestOrders.value = data.data["payments"];
  }).catch(() => {
    // console.error(err)
  }).finally(() => {
    loading.value = false
  });

  storeSortInfo.value = null;
  employeeSortInfo.value = null;
};

const updateDate = (date) => {
  chartKey.value++;
  selection.value = "";
  dateUpdated.value = true;
  if (date.length) {
    const from = date[0].toISOString().substring(0, date[0].toISOString().indexOf("T"));
    const to = date[1].toISOString().substring(0, date[1].toISOString().indexOf("T"));
    getData([from, to]);
  }
};

const storeTableChange = (pag, filters, sorter) => {
  storeSortInfo.value = sorter;
};
const employeeTableChange = (pag, filters, sorter) => {
  employeeSortInfo.value = sorter;
};

getData();

</script>

<style scoped>
@import "./index.css";
</style>
