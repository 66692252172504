<template>
  <div class="col-lg-6" v-if="showChart">
    <div class="card">
      <div class="card-header">
        <div class="vb__utils__heading mb-0">
          <strong>Объем счетов по заведениям</strong>
        </div>
      </div>
      <div class="card-body">
        <DoughnutChart :chart-data="chartData" />
      </div>
    </div>
  </div>
</template>

<script>
import apiClient from "@/services/axios";
import { DoughnutChart } from 'vue-chart-3';
import { Chart, registerables } from "chart.js";
import { ref, computed } from 'vue'

Chart.register(...registerables);

export default {
  name: 'VbOrdersStoreChart',
  components: {
    DoughnutChart,
  },
  props: {
    date: {
      type: Array,
      default: ref([]),
    },
  },
  setup(props) {
    let
      loading = ref(true),
      showChart = ref(false),
      data = ref(),
      labels = ref();

    const getData = () => {
      let from = '',
        to = '';

      if (props.date.length) {
        from = props.date[0].format('YYYY-MM-DD')
        to = props.date[1].format('YYYY-MM-DD')
        // let diff = moment.duration(props.date[1].diff(props.date[0])).days()
      }

      loading.value = true
      apiClient.get(`dashboard/chart/pie/orders?from=${from}&to=${to}`).then(({data}) => {
        let response = data.data
        if (response.data) {
          showChart.value = true
          data.value = response.data
          labels.value = response.labels
        }
        loading.value = false
      }).catch(() => {
        // console.log(err)
      }).finally(() => {
        loading.value = false
      })
    }

    const chartData = computed(() => {
      return {
        labels: labels.value,
        datasets: [
          {
            data: data.value,
            backgroundColor: ['#77CEFF', '#0079AF', '#123E6B', '#97B0C4', '#A5C8ED', '#456990', '#3871ae'],
          },
        ],
      }
    });

    getData()

    return {
      loading,
      showChart,
      chartData,
    };
  },
}
</script>
